import * as React from 'react';
import styled from 'styled-components';
import Section from '../components/Section';
import Wrapper from '../components/Wrapper';
// const FontFaceObserver = require('fontfaceobserver');

const Main = styled.main`
  background: #fff;
  padding: 150px 4rem;
  color: #101119;
  line-height: 1.75;

  h1 {
    font-family: 'Inter';
    text-shadow: none;
    color: inherit;
  }

  h3 {
    font-weight: 500;
    max-width: 700px;
    font-size: 20px;
  }

  p {
    font-size: 16px;
    max-width: 700px;
    line-height: 1.75;
    margin: 3rem 0;
  }

  a {
    color: #101119;
  }

  @media (max-width: 500px) {
    padding: 100px 2rem;
  }
`;

const PrivacyPage = () => {
  // const [fontLoaded, setFontLoaded] = React.useState(false);
  // const font = new FontFaceObserver('Inter');

  // React.useEffect(() => {
  // 	font.load().then(function () {
  // 		setFontLoaded(true);
  // 	});
  // }, [setFontLoaded, font]);

  // if (!fontLoaded) return null;

  return (
    <Main>
      <Section>
        <Wrapper>
          <h1>Privacy Policy</h1>

          <h3>
            Welcome to PNW Sports Group, The services available on and through
            this website (the “Services” and the “Site”) are provided by PNW
            Sports Group Ltd. (“PNW”).
          </h3>

          <p>
            Your use of the Services and relationship with PNW is governed by
            these User Terms of Service (this “Agreement”). The relationship
            between PNW and your organization is governed by the Organization
            Terms of Service (“Organization Terms”). By using the Services, you
            agree to be bound by this Agreement even if you are a visitor, which
            means that you simply browse the Site or any of its applications.
            PNW may at any time in its sole discretion (i) modify this Agreement
            and such modification shall be effective once posted to the Site;
            (ii) change the Site, including eliminating or discontinuing any
            Services or other feature of the Site; and/or (iii) deny or
            terminate your use of and/or access to the Site.
          </p>

          <p>
            You agree to be bound to any changes to this Agreement when you use
            the Services or access the Site immediately after any modification
            to this Agreement has been posted. It is therefore important that
            you review this Agreement regularly to ensure you are updated as to
            any changes.
          </p>

          <p>
            The term “User” refers to any visitor to the Site, unless provided
            otherwise. You are only authorized to use the Services if you agree
            to abide by all applicable laws, this Agreement and the Organization
            Terms, if applicable. Please read this Agreement carefully and save
            it. If you do not agree with it, you should leave the Site or any of
            its applications and discontinue use of the Services immediately.
          </p>

          <p>
            1. Eligibility.
            <br />
            Use of Services is void where prohibited. By using the Services, you
            represent to us that your use of the Services does not violate any
            applicable law or regulation.
          </p>

          <p>
            2. Registration.
            <br />
            Browsing of the Site’s public pages is provided free of charge to
            any person. However, access to certain functionalities of the Site
            will require you to register with and/or provide certain information
            to us. We reserve the right to decline to provide Services to any
            person for any or no reason. In order to participate in certain
            Services, you may be notified that you are required to download
            software or content and/or agree to additional terms and conditions.
            Any such additional terms are hereby incorporated by reference into
            this Agreement.
          </p>

          <p>
            3. Limited License.
            <br />
            PNW authorizes you to copy materials on this Site to your hard drive
            solely for the purpose of viewing and using the Services on your
            computer. You may also print portions of the Site in hard copy for
            the sole purpose of facilitating your personal, noncommercial use
            and retention of information from the Site, provided, that (i) you
            must retain all trademark, copyright, and other proprietary notices
            contained in the original materials, (ii) you must provide
            attribution to PNW, and if possible provide a reference to the Site,
            (iii) the material must be printed in its entirety without
            modification, reformatting or adaptation of any kind, (iv) any such
            copies are subject to the terms and conditions of this Agreement and
            remain the property of PNW, and (v) you agree to advise any person
            to whom you share the materials as to this Agreement and they must
            agree to abide by this Agreement. You may not sell or modify the
            material or reproduce, republish, display, publicly perform,
            distribute, or otherwise use the material in any way for any public
            or commercial purpose other than through the Services or the Site.
            Unauthorized use of the Services for any other purpose is
            prohibited. You may not decompile, reverse engineer, disassemble,
            rent, lease, loan, sell, sublicense, or create derivative works from
            the Site or the Services. Nor may you use any network monitoring or
            discovery software to determine the site architecture, or extract
            information about usage or users. You may not use any robot, spider,
            other automatic device, or manual process to monitor or copy our
            Site or its content without our prior written permission. Failure to
            abide by these conditions will immediately terminate this permission
            and may result in the infringement of the copyrights and/or
            trademarks and other proprietary rights of PNW or others.
          </p>

          <p>
            If you have questions about this Policy, you can write to us at:
          </p>

          <p>
            PNW Sports Group
            <br />
            14383 28 Avenue
            <br />
            Surrey, BC <br />
            Canada
            <br />
            V4P 2H2
            <br />
          </p>

          <p>
            You can email us at{' '}
            <a href="mailto:info@pnwsportsgroup.com">info@pnwsportsgroup.com</a>
          </p>
        </Wrapper>
      </Section>
    </Main>
  );
};

export default PrivacyPage;
